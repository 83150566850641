import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import AuthGuard from '../components/common/AuthGuard'
import { routePaths } from './urls'

// import CarDetailFrom from "@src/components/car-estimation/carDdetailForm";

const Home = lazy(() => import('../pages/Home'))
const Auth = lazy(() => import('../pages/Auth'))
const CarPrice = lazy(() => import('../pages/CarPrice'))
const Services = lazy(() => import('../pages/Services'))
const Profile = lazy(() => import('../pages/Profile'))
const ProfileDetails = lazy(() => import('../pages/ProfileDetails'))
const UserCars = lazy(() => import('../pages/UserCars'))
const AddNewCar = lazy(() => import('../pages/AddNewCar'))
const CarPriceEstimation = lazy(() => import('../pages/CarPriceEstimation'))
const ThirdPartyInsurance = lazy(() => import('../pages/ThirdPartyInsurance'))
const BasicInfo = lazy(() => import('../pages/BasicInfo'))
const InspectionReport = lazy(() => import('../pages/InspectionReport'))
const InspectionReportDetails = lazy(() => import('../pages/InspectionReportDetails'))
const TechnicalDiagnosis = lazy(() => import('../pages/TechnicalDiagnosis'))
const Reports = lazy(() => import('../pages/Reports'))
const ServicesReport = lazy(() => import('../pages/AftermarketReports'))
const ServicesReportDetail = lazy(() => import('../pages/AftermarketReportDetail'))
const ServicesReportCheckup = lazy(() => import('../pages/AftermarketReportCheckup'))
const OrdersHistory = lazy(() => import('../pages/OrdersHistory'))
const VehicleViolation = lazy(() => import('../pages/VehicleViolation'))
const PaletViolationHistory = lazy(() => import('../pages/PaletViolationHistory'))
const ServicesList = lazy(() => import('../pages/ServicesList'))
const OwnerInfo = lazy(() => import('../pages/OwnerInfo'))
const BodyInsurance = lazy(() => import('../pages/BodyInsurance'))
const Guarantee = lazy(() => import('../pages/Guarantee'))
const OnlineConsultant = lazy(() => import('../pages/OnlineConsultant'))
const CarDetail = lazy(() => import('../pages/CarDetail'))
const AftermarketOrderDetails = lazy(() => import('../pages/AftermarketOrderDetail'))
const AftermarketOrderPaymentDetail = lazy(() => import('../pages/AftermarketOrderPaymentDetail'))
const Notifications = lazy(() => import('../pages/NotificationsList'))
const ConsultantSubmission = lazy(() => import('../pages/ConsultantSubmission'))
const HomePageWrapper = lazy(() => import('../pages/HomePageWrapper'))
const AddMonthlyMileage = lazy(() => import('../pages/AddMonthlyMileage'))
const UpdateMileage = lazy(() => import('../pages/UpdateMileage'))
const InspectionOrderDetail = lazy(() => import('../pages/InspectionOrderDetail'))
const PeriodicServiceDetail = lazy(() => import('../pages/PeriodicServiceDetail'))
const LatestPeriodicService = lazy(() => import('../pages/LatestPeriodicService'))
const PeriodicServiceComplete = lazy(() => import('../pages/PeriodicServiceComplete'))
const PeriodicServiceUpdate = lazy(() => import('../pages/PeriodicServiceUpdate'))
const CarDetailFrom = lazy(() => import('@src/components/car-estimation/pre-car-price-estimation'))
const ProfileCarPosts = lazy(() => import('@src/pages/ProfileCarposts'))
// Add routePath which contains closeIcon in navbar
export const closeableNavbarRoutes: string[] = []

export const routes: RouteObject[] = [
  {
    path: routePaths.AUTH.url,
    element: <Auth />,
  },
  {
    path: routePaths.KARNAMEH.url,
    index: true,
    element: (
      <AuthGuard>
        <HomePageWrapper />
      </AuthGuard>
    ),
  },
  {
    path: routePaths.HOME.url,
    element: (
      <AuthGuard>
        <Home />
      </AuthGuard>
    ),
  },
  {
    path: routePaths.NOTIFICATION_LIST.url,
    element: (
      <AuthGuard>
        <Notifications />
      </AuthGuard>
    ),
  },
  {
    path: routePaths.CAR_PRICE.url,
    element: (
      <AuthGuard>
        <CarPrice />
      </AuthGuard>
    ),
  },
  {
    path: routePaths.SERVICES.url,
    element: (
      <AuthGuard>
        <Services />
      </AuthGuard>
    ),
  },
  {
    path: routePaths.PROFILE.url,
    element: (
      <AuthGuard>
        <Profile />
      </AuthGuard>
    ),
    children: [
      {
        path: routePaths.PROFILE_DETAILS.url,
        element: (
          <AuthGuard>
            <ProfileDetails />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.USER_CAR_POSTS.url,
        element: (
          <AuthGuard>
            <ProfileCarPosts />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.USER_ORDERS.url,
        children: [
          {
            index: true,
            element: (
              <AuthGuard>
                <ServicesList />
              </AuthGuard>
            ),
          },
          {
            path: routePaths.ORDERS_HISTORY.url,
            element: (
              <AuthGuard>
                <OrdersHistory />
              </AuthGuard>
            ),
          },
          {
            path: routePaths.VEHICLE_VIOLATION_HISTORY.url,
            element: (
              <AuthGuard>
                <VehicleViolation />
              </AuthGuard>
            ),
          },
          {
            path: routePaths.VEHICLE_VIOLATION_PALET_HISTORY.url,
            element: (
              <AuthGuard>
                <PaletViolationHistory />
              </AuthGuard>
            ),
          },
          {
            path: routePaths.SERVICES_ORDER_DETAILS.url,
            children: [
              {
                index: true,
                element: (
                  <AuthGuard>
                    <AftermarketOrderDetails />
                  </AuthGuard>
                ),
              },
              {
                path: routePaths.SERVICES_ORDER_PAYMENT_DETAILS.url,
                element: (
                  <AuthGuard>
                    <AftermarketOrderPaymentDetail />
                  </AuthGuard>
                ),
              },
            ],
          },
          {
            path: routePaths.INSPECTION_ORDER_DETAILS.url,
            children: [
              {
                index: true,
                element: (
                  <AuthGuard>
                    <InspectionOrderDetail />
                  </AuthGuard>
                ),
              },
            ],
          },
        ],
      },
      {
        path: routePaths.USER_CARS.url,
        element: (
          <AuthGuard>
            <UserCars />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.USER_CARS.url,
        element: (
          <AuthGuard>
            <UserCars />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: routePaths.CAR_DETAIL.url,
    children: [
      {
        path: routePaths.CAR_DETAIL.url,
        element: (
          <AuthGuard>
            <CarDetail />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.BASIC_INFO.url,
        element: (
          <AuthGuard>
            <BasicInfo />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.OWNER_INFO.url,
        element: (
          <AuthGuard>
            <OwnerInfo />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.THIRD_PARTY_INSURANCE.url,
        element: (
          <AuthGuard>
            <ThirdPartyInsurance />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.LATEST_PERIODIC_SERVICE.url,
        element: (
          <AuthGuard>
            <LatestPeriodicService />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.PERIODIC_SERVICE_COMPLETE.url,
        element: (
          <AuthGuard>
            <PeriodicServiceComplete />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.UPDATE_PERIODIC_SERVICE.url,
        element: (
          <AuthGuard>
            <PeriodicServiceUpdate />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.PERIODIC_SERVICE_DETAIL.url,
        element: (
          <AuthGuard>
            <PeriodicServiceDetail />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.REPORTS.url,
        children: [
          {
            index: true,
            element: (
              <AuthGuard>
                <Reports />
              </AuthGuard>
            ),
          },
          {
            path: routePaths.SERVICES_REPORT.url,
            children: [
              {
                index: true,
                element: (
                  <AuthGuard>
                    <ServicesReport />
                  </AuthGuard>
                ),
              },
              {
                path: routePaths.SERVICES_REPORT_DETAIL.url,
                children: [
                  {
                    index: true,
                    element: (
                      <AuthGuard>
                        <ServicesReportDetail />
                      </AuthGuard>
                    ),
                  },
                  {
                    path: routePaths.SERVICES_REPORT_CHECKUP_DETAIL.url,
                    element: (
                      <AuthGuard>
                        <ServicesReportCheckup />
                      </AuthGuard>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: routePaths.INSPECTION_REPORT.url,
            children: [
              {
                index: true,
                element: (
                  <AuthGuard>
                    <InspectionReport />
                  </AuthGuard>
                ),
              },
              {
                path: routePaths.INSPECTION_REPORT_DETAIL.url,
                element: (
                  <AuthGuard>
                    <InspectionReportDetails />
                  </AuthGuard>
                ),
              },
            ],
          },
        ],
      },

      {
        path: routePaths.BODY_INSURANCE.url,
        element: (
          <AuthGuard>
            <BodyInsurance />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.TECHNICAL_DIAGNOSIS.url,
        element: (
          <AuthGuard>
            <TechnicalDiagnosis />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.GUARANTEE.url,
        element: (
          <AuthGuard>
            <Guarantee />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: routePaths.CAR_DETAIL.url,
    children: [
      {
        path: routePaths.CAR_DETAIL.url,
        element: (
          <AuthGuard>
            <CarDetail />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.BASIC_INFO.url,
        element: (
          <AuthGuard>
            <BasicInfo />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.OWNER_INFO.url,
        element: (
          <AuthGuard>
            <OwnerInfo />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.THIRD_PARTY_INSURANCE.url,
        element: (
          <AuthGuard>
            <ThirdPartyInsurance />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.INSPECTION_REPORT.url,
        element: (
          <AuthGuard>
            <InspectionReport />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.INSPECTION_REPORT_DETAIL.url,
        element: (
          <AuthGuard>
            <InspectionReportDetails />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.BODY_INSURANCE.url,
        element: (
          <AuthGuard>
            <BodyInsurance />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.TECHNICAL_DIAGNOSIS.url,
        element: (
          <AuthGuard>
            <TechnicalDiagnosis />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.GUARANTEE.url,
        element: (
          <AuthGuard>
            <Guarantee />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: routePaths.ADD_NEW_CAR.url,
    element: (
      <AuthGuard>
        <AddNewCar />
      </AuthGuard>
    ),
  },
  {
    path: routePaths.PREPRICE_ESTIMATION.url,
    element: (
      <AuthGuard>
        <CarDetailFrom />
      </AuthGuard>
    ),
  },
  {
    path: routePaths.PRICE_ESTIMATION.url,
    element: (
      <AuthGuard>
        <CarPriceEstimation />
      </AuthGuard>
    ),
  },
  {
    path: routePaths.ONLINE_CONSULTANT.url,
    children: [
      {
        path: routePaths.ONLINE_CONSULTANT.url,
        element: (
          <AuthGuard>
            <OnlineConsultant />
          </AuthGuard>
        ),
      },
      {
        path: routePaths.PAYMENT.url,
        element: (
          <AuthGuard>
            <ConsultantSubmission />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: routePaths.MILEAGE.url,
    element: (
      <AuthGuard>
        <UpdateMileage />
      </AuthGuard>
    ),
  },
  {
    path: routePaths.MONTHLY_MILEAGE.url,
    element: (
      <AuthGuard>
        <AddMonthlyMileage />
      </AuthGuard>
    ),
  },
]

type RouteListType = {
  url: string
  path: string
  title: string
  hasAppBar: boolean
  hasDynamicAppBarName: boolean
  prevRoute?: string
}
export const routePaths: Record<string, RouteListType> = {
  KARNAMEH: {
    url: '/',
    path: '/',
    title: 'کارنامه',
    hasAppBar: false,
    hasDynamicAppBarName: false,
  },
  HOME: {
    url: '/car-profile',
    path: '/',
    title: 'خودروی من',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  AUTH: {
    url: '/auth',
    path: '/auth',
    title: 'ورود به حساب کاربری',
    hasAppBar: false,
    hasDynamicAppBarName: false,
  },
  NOTIFICATION_LIST: {
    url: '/notifications',
    path: '/notifications',
    title: 'پیام‌ها',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  CAR_PRICE: {
    url: '/car-price',
    title: 'قیمت روز',
    path: '/car-price',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  SERVICES: {
    url: '/karnameh-services',
    path: '/karnameh-services',
    title: 'خدمات کارنامه',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  MONTHLY_MILEAGE: {
    url: 'monthly-mileage/:carId',
    path: '/monthly-mileage/:carId',
    title: 'تخمین کارکرد ماهانه',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  MILEAGE: {
    url: 'mileage/:carId',
    path: '/mileage/:carId',
    title: ' کارکرد خودرو',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },

  // PROFILE NESTED ROUTES
  PROFILE: {
    url: '/profile',
    path: '/profile',
    title: 'حساب من',
    hasAppBar: false,
    hasDynamicAppBarName: false,
  },
  PROFILE_DETAILS: {
    url: 'details',
    path: '/profile/details',
    title: 'مشخصات من',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  USER_CAR_POSTS: {
    url: 'car-posts',
    path: '/profile/car-posts',
    title: 'آگهی‌های من',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  USER_ORDERS: {
    url: 'services',
    path: '/profile/services',
    title: 'سفارش‌های من',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  USER_CARS: {
    url: 'cars',
    path: '/profile/cars',
    title: 'خودرو‌های من',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },

  // CAR DETAILS NESTED ROUTES
  CAR_DETAIL: {
    url: '/car-details/:carId',
    path: '/car-details/:carId',
    title: 'اطلاعات خودرو',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  THIRD_PARTY_INSURANCE: {
    url: 'third-party-insurance',
    path: '/car-details/:carId/third-party-insurance',
    title: 'بیمه شخص ثالث',
    hasAppBar: true,
    hasDynamicAppBarName: true,
  },
  LATEST_PERIODIC_SERVICE: {
    url: 'latest-periodic-service',
    path: '/car-details/:carId/latest-periodic-service',
    title: 'اطلاعات سرویس دوره‌ای',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  PERIODIC_SERVICE_COMPLETE: {
    url: 'complete-periodic-service/:serviceId',
    path: '/car-details/:carId/complete-periodic-service/:serviceId',
    title: 'تکمیل اطلاعات سرویس دوره‌ای',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  UPDATE_PERIODIC_SERVICE: {
    url: 'update-periodic-service/:serviceId',
    path: '/car-details/:carId/update-periodic-service/:serviceId',
    title: 'ویرایش اطلاعات سرویس دوره‌ای',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  PERIODIC_SERVICE_DETAIL: {
    url: 'periodic-service-detail/:serviceId',
    path: '/car-details/:carId/periodic-service-detail/:serviceId',
    title: 'تاریخچه سرویس دوره‌ای',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  TECHNICAL_DIAGNOSIS: {
    url: 'technical-diagnosis',
    path: '/car-details/:carId/technical-diagnosis',
    title: 'معاینه فنی',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  REPORTS: {
    url: 'reports',
    path: '/car-details/:carId/reports',
    title: 'گزارش‌های ماشین',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  INSPECTION_REPORT: {
    url: 'inspection-report/:inspectionId',
    path: '/car-details/:carId/reports/inspection-report/:inspectionId',
    title: 'کارنامه ماشین',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  INSPECTION_REPORT_DETAIL: {
    url: 'details/*',
    path: '/car-details/:carId/reports/inspection-report/:inspectionId/details/*',
    title: 'گزارش کارشناسی',
    hasAppBar: true,
    hasDynamicAppBarName: true,
  },
  SERVICES_REPORT: {
    url: 'services-reports',
    path: '/car-details/:carId/reports/services-reports',
    title: 'گزارش سرویس دوره‌ای',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  SERVICES_REPORT_DETAIL: {
    url: ':servicesId',
    path: '/car-details/:carId/reports/services-reports/:servicesId',
    title: 'مشاهده گزارش سرویس دوره‌ای',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  SERVICES_REPORT_CHECKUP_DETAIL: {
    url: 'checkup',
    path: '/car-details/:carId/reports/services-reports/:servicesId/checkup',
    title: 'جزئیات چکاپ',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  BODY_INSURANCE: {
    url: 'body-insurance',
    path: '/car-details/:carId/body-insurance',
    title: 'بیمه بدنه',
    hasAppBar: true,
    hasDynamicAppBarName: true,
  },
  BASIC_INFO: {
    url: 'basic-info',
    path: '/car-details/:carId/basic-info',
    title: 'ماشین خودم',
    hasAppBar: true,
    hasDynamicAppBarName: true,
  },
  OWNER_INFO: {
    url: 'owner-info',
    path: '/car-details/:carId/owner-info',
    title: 'مشخصات مالک',
    hasAppBar: true,
    hasDynamicAppBarName: true,
  },
  GUARANTEE: {
    url: 'guarantee',
    path: '/car-details/:carId/guarantee',
    title: 'گارانتی کارنامه',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },

  ADD_NEW_CAR: {
    url: '/add-new-car',
    path: '/add-new-car',
    title: 'افزودن ماشین جدید',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  PREPRICE_ESTIMATION: {
    url: '/car-detail-before-estimation/:carId',
    path: '/car-detail-before-estimation/:carId',
    title: 'تخمین قیمت خودرو',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  PRICE_ESTIMATION: {
    url: '/estimate-car-price/:carId',
    path: '/estimate-car-price/:carId',
    title: 'تخمین قیمت خودرو',
    hasAppBar: true,
    hasDynamicAppBarName: false,
    prevRoute: '/car-profile',
  },
  ONLINE_CONSULTANT: {
    url: '/online-consultant',
    path: '/online-consultant',
    title: 'مشاوره آنلاین',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  PAYMENT: {
    url: '/online-consultant/submission',
    path: '/online-consultant/submission',
    title: 'پرداخت مشاوره آنلاین',
    hasAppBar: false,
    hasDynamicAppBarName: false,
  },

  ORDERS_HISTORY: {
    url: 'orders',
    path: '/profile/services/orders',
    title: 'درخواست‌های من',
    hasAppBar: true,
    hasDynamicAppBarName: true,
  },

  VEHICLE_VIOLATION_HISTORY: {
    url: 'vehicle-violation-history',
    path: '/profile/services/vehicle-violation-history',
    title: 'استعلام خلافی خودرو',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  VEHICLE_VIOLATION_PALET_HISTORY: {
    url: 'vehicle-violation-history/:paletId',
    path: '/profile/services/vehicle-violation-history/:paletId',
    title: 'استعلام خلافی خودرو',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  VEHICLE_VIOLATION_PALET_REPORT: {
    url: 'vehicle-violation-history/report/:paletId',
    path: '/profile/services/vehicle-violation-history/report/:paletId',
    title: 'گزارش خلافی خودرو',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  SERVICES_ORDER_DETAILS: {
    url: 'order-details/aftermarket/:orderId',
    path: '/profile/services/order-details/aftermarket/:orderId',
    title: 'جزئیات سفارش',
    hasAppBar: true,
    hasDynamicAppBarName: true,
  },
  SERVICES_ORDER_PAYMENT_DETAILS: {
    url: 'payment-detail',
    path: '/profile/services/order-details/aftermarket/:orderId/payment-detail',
    title: 'جزئیات سرویس',
    hasAppBar: true,
    hasDynamicAppBarName: false,
  },
  INSPECTION_ORDER_DETAILS: {
    url: 'order-details/inspection/:orderId',
    path: '/profile/services/order-details/inspection/:orderId',
    title: 'جزییات درخواست',
    hasAppBar: true,
    hasDynamicAppBarName: true,
  },
}

export const homeUrls = [
  routePaths.HOME.url,
  routePaths.SERVICES.url,
  routePaths.PROFILE.url,
  routePaths.CAR_PRICE.url,
]
